import { AxiosResponse } from 'axios';
import qs from 'qs';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { checkPermission } from 'src/utils/helpers';

import { api } from '../../core/api';
import setAuthorizationHeader from '../setAuthorizationHeader';

import { profileError, authSuccess, chancesSuccess } from './actions';
import { ActionTypes } from './types';

export interface IAction<T> {
    type: string;
    payload: T;
}

export interface ILogin {
    getParams: {
        username: string;
        password: string;
    };
}

interface IResponseLogin {
    ACCESS_TOKEN: string;
    user: IProfile;
}

export interface IProfile {
    id: number;
    login: string;
    permissionLevel: number;
    name: string;
    balance: number;
    cashbackPercent: number;
    cashbackBalance: number;
    scedualPaymentBalance: number;
    providerMaxLimit: number;
    parent?: IProfile;
    status: number;
    createDate: Date;
    updateDate: Date;
    version: number;
}

function* loginRequest(action: IAction<ILogin>) {
    try {
        const response: AxiosResponse<IResponseLogin> = yield api.post(`/auth/login`, action.payload);
        const validate = yield checkPermission(response.data.ACCESS_TOKEN);
        if (validate) {
            localStorage.ACCESS_TOKEN = response.data.ACCESS_TOKEN;
            setAuthorizationHeader(response.data.ACCESS_TOKEN);
            yield put(authSuccess(response.data.user));
        } else {
        }
    } catch (err) {
        console.log(err);
    }
}

function* profileRequest() {
    try {
        setAuthorizationHeader(localStorage.ACCESS_TOKEN);
        const response: AxiosResponse<IProfile> = yield api.get(`/auth/profile`);
        yield put(authSuccess(response.data));
    } catch (err) {
        yield put(profileError());
    }
}

function* chancesRequest(action: IAction<any>) {
    try {
        const parsedParams = qs.stringify({
            amountPerPage: 20,
            ...action.payload,
        });

        const { data } = yield api.get(`/chance?${parsedParams}`);
        yield put(chancesSuccess(data));
    } catch (err) {}
}

function* setChanceResult(action) {
    try {
        const response = yield api.post(`/chance/3x3cut3/op3rat1on-f1e2d3789pqr`, { ...action.payload });
        if (!response.error) {
        }
    } catch (err) {
        console.log(err);
    }
}

function* watchRequests() {
    yield takeEvery(ActionTypes.LOGIN_REQUEST, loginRequest);
    yield takeEvery(ActionTypes.PROFILE_REQUEST, profileRequest);
    yield takeEvery(ActionTypes.CHANCES_REQUEST, chancesRequest);
    yield takeEvery(ActionTypes.ON_SET_CHANCE, setChanceResult);
}

function* rootSaga() {
    yield all([fork(watchRequests)]);
}

export default rootSaga;
