import { NotificationManager } from 'react-notifications';
import { profileLogout } from '../store/auth/actions';
import { useStore } from 'react-redux';

export const translateRecursive = (errors) => {
    for (const err of errors) {
        if (err.constraints) {
            Object.values(err.constraints).forEach((value) => {
                NotificationManager.error(value, '', 3500);
            });
        }

        if (err?.children && err?.children?.length > 0) {
            for (const childKey in err.children) {
                const result = translateRecursive(err.children[childKey]);
                err.children[childKey] = result;
            }
        }
        return err;
    }
};

export const factoryErrors = (errors) => {
    if (typeof errors === 'string') {
        NotificationManager.error(errors, '', 3500);
        return;
    }

    if (typeof errors === 'object') {
        NotificationManager.error(errors.message, '', 3500);
        return;
    }

    for (const err of errors) {
        NotificationManager.error(err.message, '', 3500);
        return err;
    }
};

export const showErrorFactory = (error) => {
    switch (error.response?.status) {
        case 401:
            useStore().dispatch(profileLogout());
            NotificationManager.error(error?.response?.data?.message, '', 3500);
            break;

        case 400:
            translateRecursive(error?.response?.data?.message, '', 3500);
            break;
        case 500:
            NotificationManager.error(error?.message, '', 3500);
            break;

        case 403:
        case 404:
        case 405:
        case 406:
        case 409:
        case 413:
        case 422:
        case 424:
            factoryErrors(error?.response?.data?.message);
            break;
        default:
            break;
    }
};
