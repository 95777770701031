import React from 'react';
// import { ReactComponent as Loading } from 'src/assets/images/loading.svg';

// import Preloader from './Preloader';
// import './styles.scss';

interface IButton {
    disabled?: boolean;
    children: string;
    className: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void | Promise<void>;
    isLoading?: boolean;
    style?: any;
}

const Button: React.FC<IButton> = (props) => (
    <button type="submit" {...props}>
        {/* {props.disabled && <Preloader />} */}
        <div
            style={{
                opacity: props.disabled ? 0 : 1,
                position: 'relative',
                ...props.style,
            }}
        >
            {props.isLoading && <div className="loading-svg">{/* <Loading /> */}</div>}

            <span className={props.isLoading && 'loading-title'}>{props.children}</span>
        </div>
    </button>
);

export default React.memo(Button);
