import cn from 'classnames';
import React from 'react';

interface IInput {
    className: string;
    type?: 'text' | 'password' | 'hidden' | 'login' | 'number';
    value?: string | number;
    defaultValue?: string | number;
    placeholder?: any;
    name?: string;
    error?: number | string;
    disabled?: boolean;
    style?: object;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
    onKeyPress?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: React.FC<IInput> = (props) => (
    <div
        className={cn('input-field', {
            'input-field__error': props.error,
        })}
    >
        <input {...props} />
    </div>
);

export default Input;
