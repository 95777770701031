/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import cn from 'classnames';

import { profileLogout } from '../store/auth/actions';

import links from '../assets/links';
// import { IProfile } from '../interfaces/IProfile';

interface ILinks {
    user: any;
    locale: string;
    translate: (text: string) => string;
    profileLogout: () => void;
}

const Links: React.FC<ILinks> = ({ user, locale, translate, profileLogout }) => {
    const renderLinks = () => {
        return links.map((link) => {
            return (
                <li key={link.title}>
                    <Link to={link.link}>{translate(link.title) || link.title}</Link>
                </li>
            );
        });
    };

    return (
        <ul
            className={cn('menu-links', {
                'menu-links--rtl': locale === 'he',
            })}
        >
            {renderLinks()}
        </ul>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    locale: state.Intl.locale,
});

const mapDispatchToProps = {
    profileLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(Links));
