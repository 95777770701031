export enum BalanceType {
    TRANSFER = 1, // +
    TAKE = 2, // -
    DEPOSIT = 3, // deposit +
    OUT = 4, // out -
    REDUCE = 5, // game bet / ticket, -
    ADD = 6, // game won, +
    REFUND = 7, // failing game or game result is not valid, +
    SCEDUAL_REDUCE = 8, // zero User.scedualPaymentBalance, -
    SCEDUAL_REDUCE_BALANCE = 9, // zero User.balance, -
    SCEDUAL_PAYMENT = 10, // sced payment +
    MDBO_DEPOSIT = 11, // 0, +
    MDBO_WITHDRAW = 12, // 1, -
    CASINO_DEBIT = 13, // bet, -
    CASINO_CREDIT = 14, // win, +
    CASINO_ROLLBACK = 15, // cancel, +t +
}

export enum SystemProvider {
    SYSTEM_BALANCE = 1,
    PROVIDER_WALLET = 2,
    SYSTEM_GAME = 10,
    PROVIDER_RACING = 11,
    PROVIDER_CASINO = 12,
}

export const gameDictionary = {
    // Game status
    GAME_STATUS_CREATED: 1,
    GAME_STATUS_STARTED: 2,
    GAME_STATUS_ENDED: 3,
    GAME_STATUS_FAILED: 4,
    GAME_STATUS_DELETED: 5,

    // Name Game
    GAME_TYPE_BEST_AT_TABLE_NAME: 'Best a table',
    GAME_TYPE_CHECKPOINT_NAME: 'Checkpoint',
    GAME_TYPE_ONE_ON_ONE_NAME: 'One on One',
    GAME_TYPE_MONTH_PRIZE_NAME: 'Month prize',
    GAME_TYPE_DAILY_NAME: 'Daily',
    GAME_TYPE_JACKPOT_NAME: 'Jackpot',

    // Game type
    GAME_TYPE_BEST_AT_TABLE: 1,
    GAME_TYPE_CHECKPOINT: 2,
    GAME_TYPE_ONE_ON_ONE: 3,
    GAME_TYPE_MONTH_PRIZE: 4,
    GAME_TYPE_DAILY: 5,
    GAME_TYPE_JACKPOT: 6,
    GAME_TYPE_CASINO: 7,
    GAME_TYPE_CHANCE: 8,

    // checkpoint point
    GAME_CHECKPOINT_FIRST: 1,
    GAME_CHECKPOINT_SECOND: 2,
    GAME_CHECKPOINT_THIRD: 3,

    // Failed status
    GAME_STATUS_NOT_ENOUGH_PLAYERS: 1,
    GAME_STATUS_NOT_ENOUGH_MATCHES: 2,
    GAME_STATUS_NOT_ENOUGH_PLAYERS_AND_MATCHES: 3,

    // Default value
    GAME_DEFAULT_CASHBACK_PERCENT: 10,
};

export enum EnumValidate {
    VALIDATE_LANG_LENGTH = 2,
    VALIDATE_COUNTRY_LENGTH = 2,
    VALIDATE_CURRENCY_LENGTH = 3,
    VALIDATE_CODE_LENGTH = 8,
    VALIDATE_STRING_LENGTH_MIN = 2,
    VALIDATE_STRING_LENGTH_MAX = 100,
    VALIDATE_SECURE_LENGTH_MIN = 8,
    VALIDATE_SECURE_LENGTH_MAX = 32,
    VALIDATE_SECURE_DB_LENGTH_MAX = 100,
    VALIDATE_PAYLOAD_DB_LENGTH_MAX = 500,
    VALIDATE_LOGIN_LENGTH_MIN = 4,
    VALIDATE_LOGIN_LENGTH_MAX = 32,
    VALIDATE_EMAIL_LENGTH_MIN = 6,
    VALIDATE_EMAIL_LENGTH_MAX = 254,
    VALIDATE_PHONE_LENGTH_MIN = 10,
    VALIDATE_PHONE_LENGTH_MAX = 15,
    VALIDATE_PASSWORD_LENGTH_MIN = 4,
    VALIDATE_PASSWORD_LENGTH_MAX = 32,
    VALIDATE_NAME_LENGTH_MIN = 4,
    VALIDATE_NAME_LENGTH_MAX = 500,
}

export enum GlobalGameTypeEnum {
    'chance' = 500,
    'chance-speed' = 501,
    'livecasino' = 1,
    'poker' = 2,
    'virtual-sports' = 3,
    'sportsbook' = 4,
    'live-casino-table' = 5,
    'video-slots' = 6,
    'table-games' = 7,
    'video-poker' = 8,
    'virtual-games' = 9,
    'scratch-cards' = 10,
    'video-bingo' = 11,
    'tournaments' = 12,
    'livegames' = 13,
    'crash-games' = 14,
    'fast-games' = 15,
    'OT' = 777,
}
