import React, { FC } from 'react';
import './datePickerComponent.scss';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface IProps {
    value: Date;
    onChange: (date: Date) => void;
}

const DatePickerComponent: FC<IProps> = ({ value = new Date(), onChange }): JSX.Element => (
    <div className="picker">
        <DatePicker
            selected={value}
            onChange={(date: Date) => onChange(date)}
            showTimeSelect
            showTimeSelectSeconds
            timeFormat="HH:mm:ss"
            dateFormat="dd/MM/yyyy h:mm:ss aa"
            maxDate={new Date()}
        />
    </div>
);

export default DatePickerComponent;
