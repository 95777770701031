export default {
    locale: 'ru',
    messages: {
        'Amount': 'Количество',
        'Balance': 'Баланс',
        'Balance_type': 'Тип баланса',
        'Game_provider': 'Провайдер игры',
        'System_provider': 'Системный провайдер',
        'Provider_game_type': 'Тип игры провайдера',
        'Provider_game_id': 'Идентификатор игры провайдера',
        'Provider_game_name': 'Название игры провайдера',
        'Provider_transaction_id': 'Идентификатор транзакции провайдера',
        'Chance': 'Шанс',
        'Date': 'Дата',
        'Create': 'Создать',
        'Logout': 'Выход',
        'No content': 'Нет данных',
        'Target': 'Цель',
        'Success': 'Успех',
        'Key': 'Ключ',
    },
};
