import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

interface JwtPayload {
    jwtPayloadData: {
        permissionLevel: number;
    };
}

export const checkPermission = (token: string): boolean => {
    try {
        const decodedToken: JwtPayload = jwtDecode(token);
        const { permissionLevel } = decodedToken.jwtPayloadData;
        if (permissionLevel === 8) return true;
        return false;
    } catch (error) {
        return false;
    }
};

export function changeBalance(data: number, type?: boolean, property?: string | string[]): number;
export function changeBalance(data: object[], type?: boolean, property?: string | string[]): object[];
export function changeBalance(data: any, type?: boolean, property: string | string[] = 'balance'): any {
    if (typeof data !== 'object') {
        return type ? data * 100 : data / 100;
    }

    if (Array.isArray(property)) {
        return data.map((item) => {
            for (const key in item) {
                if (property.includes(key)) {
                    item[key] /= 100;
                }
            }

            return item;
        });
    }
    return data.map((item) => ({
        ...item,
        [property]: item[property] / 100,
    }));
}

export const formatUserTimeZone = (inputDate: string) => {
    const parsedDate = moment(inputDate);

    const userTimeZoneOffset = new Date().getTimezoneOffset();

    const formattedDate = parsedDate.utcOffset(userTimeZoneOffset).format();

    return formattedDate;
};

export const dateToTimestamp = (inputDate: Date) => {
    const parsedDate = moment(inputDate);

    const timestamp = parsedDate.valueOf();
    return Math.floor(moment.utc(timestamp).valueOf() / 1000);
};

export const getCardByValue = (cardValue: number): string => {
    switch (cardValue) {
        case 0:
            return 'None';
        case 7:
            return '7';
        case 8:
            return '8';
        case 9:
            return '9';
        case 10:
            return '10';
        case 11:
            return 'J';
        case 12:
            return 'Q';
        case 13:
            return 'K';
        case 14:
            return 'A';
        default:
            return 'None';
    }
};
