import { IChance } from 'src/pages/Chance';
import { changeBalance } from 'src/utils/helpers';

import { ActionTypes } from './types';

interface IInitialState {
    user: any | {};
    isAuthenticated: boolean;
    isLoaded: boolean;
    authFailure: boolean;
    ticketHistory: {
        data: any[];
    };
    chance: {
        games: {
            data: IChance[];
        };
    };
}

export const initialState: IInitialState = {
    user: {},
    isAuthenticated: false,
    isLoaded: false,
    authFailure: false,
    ticketHistory: {
        data: [],
    },
    chance: {
        games: {
            data: [],
        },
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.AUTH_SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
                isAuthenticated: true,
                isLoaded: true,
                authFailure: false,
            };
        }

        case ActionTypes.PROFILE_ERROR: {
            return {
                ...state,
                authFailure: true,
            };
        }

        case ActionTypes.USER_EDIT: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            };
        }

        // Chances
        case ActionTypes.CHANCES_SUCCESS: {
            return {
                ...state,
                chance: {
                    ...state.chance,
                    games: {
                        ...state.chance.games,
                        data: changeBalance(action.payload.data, false, 'ticketPrice'),
                        totalCount: action.payload.totalCount,
                        pageNumber: action.payload.pageNumber,
                        amountPerPage: action.payload.amountPerPage,
                    },
                    chancesLoaded: true,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
