import React from 'react';

const NoContent: React.FC<any> = (props) => (
    <div className="no-content">
        <span>
            {props.translate('No content')} :{' '}
            <span role="img" aria-label="Thinking Face">
                🤔
            </span>
        </span>
    </div>
);

export default React.memo(NoContent);
