import React from 'react';
import { ReactNotifications, Store } from 'react-notifications-component';
import { connect } from 'react-redux';
import 'react-notifications-component/dist/theme.css';

interface INotificationsProps {
    manageHandlers: {
        handlerStatus: {
            status: string;
            message: string;
        };
    };
}

interface INotificationsState {
    handlerStatus: {
        status: string;
        message: string;
    };
}

class Notifications extends React.PureComponent<INotificationsProps, INotificationsState> {
    componentDidMount() {
        window.NotificationManager = Store;
    }

    componentDidUpdate() {
        const { handlerStatus } = this.props.manageHandlers;

        this.createNotification(handlerStatus);
    }

    createNotification = (type) => {
        switch (type.status) {
            // case "info":
            //   NotificationManager.info("Info message");
            //   break;
            case 'success':
                Store.addNotification({
                    title: type.message,
                    message: '',
                    type: 'success',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
                break;
            // case "warning":
            //   NotificationManager.warning("Warning message", "Close after 3000ms", 3000);
            //   break;
            case 'error':
                Array.isArray(type.message)
                    ? type.message.forEach((error) => {
                          if (error.constraints) {
                              Store.addNotification({
                                  title: JSON.stringify(error.constraints),
                                  message: '',
                                  type: 'danger',
                                  insert: 'top',
                                  container: 'top-right',
                                  animationIn: ['animate__animated', 'animate__fadeIn'],
                                  animationOut: ['animate__animated', 'animate__fadeOut'],
                                  dismiss: {
                                      duration: 5000,
                                      onScreen: true,
                                  },
                              });
                          } else {
                              Store.addNotification({
                                  title: error,
                                  message: '',
                                  type: 'danger',
                                  insert: 'top',
                                  container: 'top-right',
                                  animationIn: ['animate__animated', 'animate__fadeIn'],
                                  animationOut: ['animate__animated', 'animate__fadeOut'],
                                  dismiss: {
                                      duration: 5000,
                                      onScreen: true,
                                  },
                              });
                          }
                      })
                    : Store.addNotification({
                          title: type.message,
                          message: '',
                          type: 'danger',
                          insert: 'top',
                          container: 'top-right',
                          animationIn: ['animate__animated', 'animate__fadeIn'],
                          animationOut: ['animate__animated', 'animate__fadeOut'],
                          dismiss: {
                              duration: 5000,
                              onScreen: true,
                          },
                      });
                break;
        }
    };

    render() {
        return (
            <div>
                <ReactNotifications />
            </div>
        );
    }
}

export default connect((state: any) => ({ manageHandlers: state.manageHandlers }), {})(Notifications);
