/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import cn from 'classnames';

import { profileLogout, profileRequest } from '../../store/auth/actions';

import MobileMenu from '../Mobile/MobileMenu';
import { withRouter } from 'src/withRouter';
import Locale from '../Locale';

interface IHeader {
    user: any;
    history: any;
    isAuthenticated: boolean;
    balanceHistory: any;
    profileRequest(): void;
    translate(key: string): string;
    profileLogout(): void;
}

const Header: React.FC<IHeader> = (props) => {
    const [showMobileMenuModal, switchMobileMenu] = React.useState(false);
    const [isTimerStarted, setIsTimerStarted] = React.useState(false);
    const [isNotificationShow, setIsNotificationShow] = React.useState(false);
    const { history } = props;

    useEffect(() => {
        if (props.isAuthenticated && !isTimerStarted) {
            setIsTimerStarted(true);

            setTimeout(async function updateBalance() {
                props.profileRequest();

                setTimeout(updateBalance, 15000);
            }, 15000);
        }

        if (!isNotificationShow && localStorage.roomNotifications) {
            JSON.parse(localStorage.roomNotifications).forEach((notification) => {
                window.NotificationManager.info('', notification, 86400000, () => {
                    const currentStorageState = localStorage.roomNotifications ? JSON.parse(localStorage.roomNotifications) : '';
                    const newStorageState = currentStorageState.filter((item) => item !== notification);
                    localStorage.roomNotifications = JSON.stringify(newStorageState);
                });
            });

            setIsNotificationShow(true);
        }
    }, [isTimerStarted, isNotificationShow, props]);

    const handleLogout = () => {
        props.profileLogout();
        props.history.push('/');
    };

    return (
        <>
            <MobileMenu
                isOpen={showMobileMenuModal}
                user={props.user}
                profileLogout={props.profileLogout}
                translate={props.translate}
                switchMobileMenu={() => switchMobileMenu(!showMobileMenuModal)}
            />

            <header
                className={cn({
                    'best-at-table-color': history.location.pathname === '/best-at-table/games',
                    'one-on-one-color': history.location.pathname === '/one-on-one',
                    'checkpoint-color': history.location.pathname === '/checkpoint',
                    'month-prize-color': props.history.location.pathname === '/month-prize',
                    'jackpot-color': props.history.location.pathname === '/jackpot',
                    'daily-color': props.history.location.pathname === '/daily',
                })}
            >
                <div className="header__inner">
                    <div className="right-side">
                        <div className="links">
                            <Locale />
                            {props.isAuthenticated ? (
                                <>
                                    <div></div>
                                    <div className="userBlock" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <div className="links__link string-trim">{props.user.login}</div>
                                        <div onClick={handleLogout} className="logoutButton btn gold-btn">
                                            {props.translate('Logout')}
                                        </div>
                                    </div>

                                    <div
                                        className="links__link mobile-menu__btn"
                                        onClick={() => switchMobileMenu(!showMobileMenuModal)}
                                    >
                                        <div /> <div /> <div />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default withRouter(
    connect(
        (state: any) => ({
            user: state.auth.user,
            isAuthenticated: state.auth.isAuthenticated,
        }),
        {
            profileLogout,
            profileRequest,
        },
    )(withTranslate(Header)),
);
