import React, { useState } from 'react';

import LoginForm from '../LoginForm/Form';

import ModalHOC from './ModalHOC';

const LoginModal: React.FC<any> = (props) => {
    const [login, setLogin] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = async (values) => {
        setIsLoading(true);
        await props.loginRequest(values);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const onChangeUsername = (name) => {
        setLogin(name?.toLowerCase());
    };

    return (
        <div className="modal-container__inner modal-container__login">
            <div className="modal-container__header">Log in</div>

            <LoginForm
                onSubmit={onSubmit}
                handlerStatus={props.handlerStatus}
                isLoading={isLoading}
                switchModal={true}
                values={{ login }}
                handleChange={(e) => {
                    if (e?.name === 'login') {
                        onChangeUsername(e.value);
                    }
                }}
            />
        </div>
    );
};

export default ModalHOC(LoginModal);
