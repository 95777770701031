import axios from 'axios';
import packageJson from '../../../package.json';
import { profileLogout } from '../../store/auth/actions';
import { checkPermission } from '../../utils/helpers';
import { showErrorFactory } from '../../utils/helpersError';
console.log('version:', packageJson?.version);

export let configData = {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
};
const locale = localStorage.locale ? localStorage.locale : 'en';
export const api = axios.create({
    baseURL: configData?.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': String(locale),
    },
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.ACCESS_TOKEN;
        if (token) {
            const validate = checkPermission(token);
            if (!validate) {
                profileLogout();
                return;
            }
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => error,
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        showErrorFactory(error);
        return { error };
    },
);

fetch('./config.json')
    .then((response) => {
        try {
            return response.json();
        } catch (e) {
            new Error(e);
        }
    })
    .then((config) => {
        configData = config;
        api.defaults.baseURL = configData?.REACT_APP_API_URL;
    })
    .catch(() => console.log('Failed to load config.json'))
    .finally(() => {
        // TODO: rm for prod
        console.log('REACT_APP_API_URL:', configData?.REACT_APP_API_URL);
    });
