import { action } from 'typesafe-actions';

import { IAction, IProfile } from './sagas';
import { ActionTypes } from './types';

export const loginRequest = (data: { username: string; password: string }) => action(ActionTypes.LOGIN_REQUEST, data);
export const authSuccess = (data: IProfile) => action(ActionTypes.AUTH_SUCCESS, data);

export const profileRequest = () => action(ActionTypes.PROFILE_REQUEST);
export const profileError = () => {
    localStorage.removeItem('ACCESS_TOKEN');
    return action(ActionTypes.PROFILE_ERROR);
};

export const userEdit = (data: { balance: number; scedualPaymentBalance: number }) => action(ActionTypes.USER_EDIT, data);
export const profileLogout = () => action(ActionTypes.PROFILE_LOGOUT);

export const chancesRequest = (data: IAction<any>) => action(ActionTypes.CHANCES_REQUEST, data);
export const chancesSuccess = (data: any) => action(ActionTypes.CHANCES_SUCCESS, data);

export const onSetChance = (data: object) => action(ActionTypes.ON_SET_CHANCE, data);
