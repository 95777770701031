export default {
    locale: 'he',
    messages: {
        'Amount': 'כמות',
        'Balance': 'יתרה',
        'Balance_type': 'סוג יתרה',
        'Game_provider': 'ספק משחק',
        'System_provider': 'ספק מערכת',
        'Provider_game_type': 'סוג משחק ספק',
        'Provider_game_id': 'מזהה משחק ספק',
        'Provider_game_name': 'שם משחק ספק',
        'Provider_transaction_id': 'מזהה עסקה של ספק',
        'Chance': 'הזדמנות',
        'Date': 'תאריך',
        'Create': 'צור',
        'Logout': 'התנתק',
        'No content': 'אין תוכן',
        'Target': 'מטרה',
        'Success': 'הצלחה',
        'Key': 'מפתח',
    },
};
