/* eslint-disable */
import { ComponentType } from 'react';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export interface WithRouterProps {
    history: {
        back: () => void;
        goBack: () => void;
        location: ReturnType<typeof useLocation>;
        push: (url: string) => void;
    };
    location: ReturnType<typeof useLocation>;
    match: {
        params: {
            id: string;
            name: string;
            tabName: string;
            templateId: string;
        };
    };
    navigate: ReturnType<typeof useNavigate>;
}

export const withRouter = <P extends object>(Component: ComponentType<P>) => {
    return (props: Omit<P, keyof WithRouterProps>) => {
        const location = useLocation();
        const match = { params: useParams() };
        const navigate = useNavigate();

        const history = {
            back: () => navigate(-1),
            goBack: () => navigate(-1),
            location,
            push: (url: string) => navigate(url),
            replace: (url: string) =>
                navigate(url, {
                    replace: true,
                }),
        };

        return <Component history={history} location={location} match={match} navigate={navigate} {...(props as P)} />;
    };
};
