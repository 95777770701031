export default {
    locale: 'en-US',
    messages: {
        'Amount': 'Amount',
        'Balance': 'Balance',
        'Balance_type': 'Balance type',
        'Game_provider': 'Game provider',
        'System_provider': 'System provider',
        'Provider_game_type': 'Provider game type',
        'Provider_game_id': 'Provider game ID',
        'Provider_game_name': 'Provider game name',
        'Provider_transaction_id': 'Provider transaction ID',
        'Chance': 'Chance',
        'Date': 'Date',
        'Create': 'Create',
        'Logout': 'Logout',
        'No content': 'No data',
        'Target': 'Target',
        'Success': 'Success',
        'Key': 'Key',
    },
};
