import React, { Component } from 'react';

import { inputPatterns } from '../assets/common/patterns';

const FormHOC = (WrappedComponent, defaultValues, validator) => {
    class ComponentHOC extends Component<any, any> {
        constructor(props) {
            super(props);
            this.state = {
                values: this.props.customValues || defaultValues,
                errors: {},
            };
        }

        handleChange = (e, myTarget) => {
            try {
                const dataPattern = e.currentTarget.dataset.pattern.split(', ');
                if (e.target.value && dataPattern.length) {
                    for (const pattern of dataPattern) {
                        if (!inputPatterns[pattern].test(+e.target.value)) return;
                    }
                }
            } catch (err) {}

            if (this.props.handleChange) {
                this.props.handleChange({ value: e.target.value, name: e.target.name });
            }
            this.setState({
                values: {
                    ...this.state.values,
                    ...(myTarget || { [e.target.name]: e.target.value }),
                },
            });
        };

        handleChangeCheckbox = (value, target) => {
            const bool = Boolean(value);
            this.setState({
                values: {
                    ...this.state.values,
                    ...{ [target]: bool },
                },
            });
        };

        selectDate = (date, type) => {
            this.setState({
                values: {
                    ...this.state.values,
                    [type]: date,
                },
            });
        };

        selectPrice = (selectedPrice) => {
            this.setState({
                values: {
                    ...this.state.values,
                    price: selectedPrice,
                },
            });
        };

        selectInputFilter = (value, name) => {
            this.setState({
                values: {
                    ...this.state.values,
                    [name]: value,
                },
            });
        };

        onSubmit = (e) => {
            e.preventDefault();
            const { isValid, errors } = validator(this.state.values);

            this.setState({ errors });

            if (!isValid) {
                return;
            }

            this.props.onSubmit(this.state.values);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                    selectDate={this.selectDate}
                    selectInputFilter={this.selectInputFilter}
                    selectPrice={this.selectPrice}
                    onSubmit={this.onSubmit}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                />
            );
        }
    }

    return ComponentHOC;
};

export default FormHOC;
