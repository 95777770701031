export default [
    {
        title: 'Balance',
        link: '/create-trans-by-admin',
        exclude: [] as any,
    },
    {
        title: 'Chance',
        link: '/chance',
        exclude: [] as any,
    },
];
