import './App.css';
import React, { useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { connect, useDispatch } from 'react-redux';
import { withTranslate, IntlActions } from 'react-redux-multilingual';

import 'react-notifications/lib/notifications.css';
import Header from './components/Header';
import Notifications from './components/Notifications';
import RenderRoutes from './routes/RenderRoutes';

import './styles/normalize.css';
import './styles/common.scss';
import './styles/react-datepicker.css';

declare global {
    interface JQuery {
        marquee: any;
    }
    interface Window {
        NotificationManager: any;
        Swiper: any;
        marquee: any;
        md: {
            mobile: () => {};
        };
        resizeViewport: () => {};
    }
}

function App() {
    const [locale] = React.useState(localStorage.locale);
    const dispatch = useDispatch();
    React.useEffect(() => {
        const body = document.querySelector('body') as HTMLElement;
        body.classList.remove('rtl');
    }, [locale]);
    useEffect(() => {
        const local = locale ? locale : 'en';
        dispatch(IntlActions.setLocale(local));
    }, []);

    return (
        <>
            <Header />

            <main id="main-container">
                <RenderRoutes />
            </main>
            <Notifications />
            <NotificationContainer />
        </>
    );
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated,
    authFailure: state.auth.authFailure,
});
export default connect(mapStateToProps, null)(withTranslate(App));
