/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { IntlActions } from 'react-redux-multilingual';
import Select from 'react-select';

interface ILocale {
    dispatch: any;
}

const Locale: React.FC<ILocale> = ({ dispatch }) => {
    const [locale, setLocale] = React.useState(localStorage.locale);

    const selectLocale = (selectedOption) => {
        localStorage.locale = selectedOption.value;

        const body = document.querySelector('body') as HTMLElement;
        selectedOption.value === 'he' ? body.classList.add('rtl') : body.classList.remove('rtl');

        setLocale(selectedOption.value);
        dispatch(IntlActions.setLocale(selectedOption.value));
    };

    if (!locale) {
        selectLocale({ value: 'he' });
    }

    const customStyles = {
        control: (styles) => {
            return {
                ...styles,
                backgroundColor: '#0c0c0c',
                border: '2px solid #e6bc73 !important',
                borderRadius: '14px',
                boxShadow: 'none !important',
                width: '92px',
                minHeight: '38px !important',
                height: '25px !important',
                margin: '0',
                marginTop: '2px;',
            };
        },
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: isFocused ? '#68522c' : '#e6bc73',
                cursor: 'pointer',
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#0c0c0c',
                margin: '0 16px 0 16px',
                padding: 0,
                width: 50,
                left: '-12px',
            };
        },
    };

    const options = [
        {
            value: 'en',
            label: (
                <div>
                    <img alt="EN" src={'/assets/images/lang/en.png'} height="20px" width="auto" />
                </div>
            ),
        },
        {
            value: 'ru',
            label: (
                <div>
                    <img alt="RU" src={'/assets/images/lang/ru.png'} height="20px" width="auto" />
                </div>
            ),
        },
        {
            value: 'he',
            label: (
                <div>
                    <img alt="HE" src={'/assets/images/lang/he.png'} height="20px" width="auto" />
                </div>
            ),
        },
    ];

    return (
        <Select
            name="currentType"
            value={{
                label: (
                    <div>
                        <img
                            height="19px"
                            width="auto"
                            alt={'/assets/images/lang/en.png'}
                            src={`/assets/images/lang/${locale ? locale : 'en'}.png`}
                        />
                    </div>
                ),
            }}
            onChange={selectLocale}
            styles={customStyles}
            options={options}
            isSearchable={false}
        />
    );
};

export default connect(null, (dispatch) => ({
    dispatch,
}))(Locale);
