export const inputPatterns = {
    onlyNumbers: /^[0-9]+$/,
    rangeNumbers: /^0*(?:[0-9][0-9]?|100)$/,
    trimLeadingZero: /[^0+]/,
};

export const regularOptions = [
    { status: false, name: '1' },
    { status: false, name: 'X' },
    { status: false, name: '2' },
];
