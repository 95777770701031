import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';

import { loginRequest, userEdit } from '../../store/auth/actions';

const ModalHOC = (WrappedComponent) => {
    class ComponentHOC extends Component<any, any> {
        constructor(props) {
            super(props);
            this.state = {
                handlerStatus: {
                    status: '',
                    message: '',
                },
            };
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props} />;
        }
    }

    return withTranslate(
        connect(
            (state: any) => ({
                user: state.auth.user,
            }),
            {
                loginRequest,
                userEdit,
            },
        )(ComponentHOC),
    );
};

export default ModalHOC;
