/* eslint-disable */
import React from 'react';
import Select from 'react-select';

import { getCardByValue } from 'src/utils/helpers';

export interface ICard {
    cardNumber: string;
    currentCard: number;
    selectCard(cardNumber: string, selectedOption: string): void;
}

const Card: React.FC<ICard> = (props) => {
    const getRandomColor = (): string => {
        const characters = 'CDHS';
        return characters.charAt(Math.floor(Math.random() * characters.length));
    };

    const customStyles = {
        singleValue: (styles) => {
            return {
                ...styles,
                color: '68522c',
            };
        },
        input: (styles) => {
            return {
                ...styles,
                color: '68522c',
            };
        },
        control: (styles) => {
            return {
                ...styles,
                backgroundColor: '#0c0c0c',
                border: '2px solid #e6bc73 !important',
                borderRadius: '14px',
                boxShadow: 'none !important',
                margin: '0',
            };
        },
        option: (styles, { isFocused, isHovered }) => {
            return {
                ...styles,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: isFocused ? '#68522c' : '#e6bc73',
                color: isFocused ? '#e6bc73' : '#68522c',
                width: 100,
                cursor: 'pointer',
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#e6bc73',
                margin: '0 65px',
                padding: 0,
                width: 100,
            };
        },
    };

    const options = [
        { value: '0', label: <span>None</span> },
        { value: '7', label: <span>7</span> },
        { value: '8', label: <span>8</span> },
        { value: '9', label: <span>9</span> },
        { value: '10', label: <span>10</span> },
        { value: '11', label: <span>J</span> },
        { value: '12', label: <span>Q</span> },
        { value: '13', label: <span>K</span> },
        { value: '14', label: <span>A</span> },
    ];

    return (
        <div className="chance__card">
            <Select
                name="currentCard"
                value={{ label: <span>{getCardByValue(props.currentCard)}</span> }}
                onChange={(selectedOption) => props.selectCard(props.cardNumber, selectedOption as any)}
                styles={customStyles as any}
                options={options}
                isSearchable={false}
            />
            <img className="chance__card-image" src={`assets/images/cards/${props.currentCard}${getRandomColor()}.png`} alt="" />
        </div>
    );
};

export default Card;
