/* eslint-disable */
import React from 'react';

import Card from './Card';

export interface ICardField {
    selectedCards: IChanceBetAccurate;
    selectCard(cardNumber: string, selectedOption: string): void;
}

export interface IChanceBetAccurate {
    first?: number;
    second?: number;
    third?: number;
    fourth?: number;
}

const CardField: React.FC<ICardField> = (props) => {
    return (
        <div className="chance__card-field">
            <Card cardNumber="first" currentCard={props.selectedCards.first || 0} selectCard={props.selectCard} />
            <Card cardNumber="second" currentCard={props.selectedCards.second || 0} selectCard={props.selectCard} />
            <Card cardNumber="third" currentCard={props.selectedCards.third || 0} selectCard={props.selectCard} />
            <Card cardNumber="fourth" currentCard={props.selectedCards.fourth || 0} selectCard={props.selectCard} />
        </div>
    );
};

export default CardField;
