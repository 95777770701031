import './input.scss';
import classNames from 'classnames';

function Input({
    placeholder = '',
    className,
    value = '',
    onChange = () => '',
    label = '',
    hideLabel = false,
    error = null,
    id = '',
    type = 'text',
    readOnly = false,
    translate,
}) {
    return (
        <div className={classNames(error ? 'inputError' : 'input', className)}>
            {!hideLabel && <span>{label}</span>}
            <input placeholder={placeholder} value={value} onChange={onChange} type={type} id={id} readOnly={readOnly} />
            {error && <span className={classNames('error_message', className)}>{translate(error)}</span>}
        </div>
    );
}

export default Input;
