/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import { CSSTransition } from 'react-transition-group';
import { gameDictionary } from 'src/assets/common/enum';
import Button from 'src/components/Button';
import { premisionLevel } from 'src/components/UserCreateBalanceForm';
import { IProfile } from 'src/store/auth/sagas';
import { withRouter } from 'src/withRouter';

import NoContent from '../../components/NoContent';
import { chancesRequest, onSetChance } from '../../store/auth/actions';

import CardField from './CardField';
import Input from 'src/components/Input';

export interface IChance {
    id: number;
    ticketPrice: number;
    cashbackPercent: number;
    currentTickets: number;
    status: number;
    tickets: any[];
    result: any;
    startDate: Date;
    createDate: Date;
    updateDate: Date;
    version: number;
}

class Chance extends React.Component<any, any> {
    private interval;

    constructor(props) {
        super(props);

        this.state = {
            currentChance: {} as IChance,
            currentBetType: 0,
            currentBetPrediction: '',
            selectedCards: {},
            chanceHistoryIsShown: false,
            price: 0,
            key: '',
        };
    }

    static getDerivedStateFromProps(props, state): any {
        if (!state.chanceHistoryIsShown) {
            let currentChance: IChance | null;

            if (props.chance.data.length === 0) {
                currentChance = null;
            } else {
                const sortedChances: IChance[] = props.chance.data.sort((first, second) => {
                    return second.startTime - first.startTime;
                });

                currentChance = sortedChances[0];
            }
            return {
                currentChance,
            };
        } else {
            return null;
        }
    }

    componentDidMount(): void {
        if (premisionLevel() !== 8) return;
        this.updateChance();
        this.interval = setInterval(() => {
            this.updateChance();
        }, 30000);
    }

    updateChance = () => {
        this.props.chancesRequest({
            status: gameDictionary.GAME_STATUS_STARTED,
            type: 501,
        });
    };

    selectBet = (event: any, type: number, prediction: string | any) => {
        document.querySelector('.chance__button_active')?.classList.remove('chance__button_active');
        event.currentTarget.classList.add('chance__button_active');

        this.setState({
            currentBetType: type,
            currentBetPrediction: prediction,
        });
    };

    setResult = () => {
        this.props.onSetChance({
            id: this.props.chance.data[0].id,
            data: this.state.selectedCards,
            requestToken: this.state.key,
        });
    };

    selectCard = (cardNumber: string, selectedOption: any): void => {
        this.setState({
            selectedCards: {
                ...this.state.selectedCards,
                [cardNumber]: Number(selectedOption.value),
            },
        });
    };

    handleChange = (e: any) => {
        const value = e.target.value;
        this.setState({ key: value });
    };

    handlePage = (pageNumber: number): void => {
        this.props.chancesRequest({
            status: gameDictionary.GAME_STATUS_ENDED,
            pageNumber,
            type: 501,
        });
    };

    switchHistory = (): void => {
        const { chanceHistoryIsShown } = this.state;

        if (chanceHistoryIsShown) {
            this.props.chancesRequest({
                status: gameDictionary.GAME_STATUS_STARTED,
                type: 501,
            });
        } else {
            this.props.chancesRequest({
                status: gameDictionary.GAME_STATUS_ENDED,
                type: 501,
            });
        }

        this.setState({
            chanceHistoryIsShown: !chanceHistoryIsShown,
        });
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(): JSX.Element {
        const { chancesLoaded, translate } = this.props;
        const { currentChance } = this.state;

        if (premisionLevel() !== 8) return;
        return (
            <>
                <div className="page-container">
                    <CSSTransition in={chancesLoaded} unmountOnExit={true} timeout={2000} classNames="modalWindowAnimation">
                        <div className="chance">
                            {currentChance ? (
                                <div className="chance__play">
                                    <CardField selectedCards={this.state.selectedCards} selectCard={this.selectCard} />
                                    <Input
                                        className="input gold-input"
                                        placeholder={this.props.translate('Key')}
                                        type="text"
                                        name="password"
                                        value={this.state.key}
                                        onChange={this.handleChange}
                                    />
                                    <Button
                                        style={{ marginTop: '10px' }}
                                        className="btn gold-btn"
                                        type="button"
                                        onClick={this.setResult}
                                    >
                                        Set result
                                    </Button>
                                </div>
                            ) : (
                                <NoContent translate={translate} />
                            )}
                        </div>
                    </CSSTransition>
                </div>
            </>
        );
    }
}

interface IStoreState {
    auth: {
        user: IProfile;
        chance: {
            games: {
                data: IChance[];
            };
            chancesLoaded: boolean;
        };
    };
}

const mapStateToProps = (state: IStoreState) => ({
    user: state.auth.user,
    chance: state.auth.chance.games,
    chancesLoaded: state.auth.chance.chancesLoaded,
});

const mapDispatchToProps = {
    chancesRequest,
    onSetChance,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(Chance)));
