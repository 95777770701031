import React from 'react';

import Button from '../Button';
import FormHOC from '../FormHOC';
import Input from '../Input';

import validator from './validator';

interface ILoginForm {
    values: {
        login: string;
        password: string;
    };
    errors: {
        login: any;
        password: any;
    };
    isLoading: boolean;
    onSubmit: () => void;
    handleChange: () => void;
}

const Form: React.FC<ILoginForm> = (props) => (
    <form onSubmit={props.onSubmit}>
        <div className="modal-container__inputs">
            <Input
                className="input gold-input"
                placeholder="Login"
                type="text"
                name="login"
                value={props.values.login}
                error={props.errors.login}
                onChange={props.handleChange}
            />
            <Input
                className="input gold-input"
                placeholder="Password"
                type="password"
                name="password"
                value={props.values.password}
                error={props.errors.password}
                onChange={props.handleChange}
            />
        </div>
        <div className="modal-container__btns">
            <Button isLoading={props.isLoading} className="btn gold-btn" type="submit">
                Log in
            </Button>
        </div>
    </form>
);

export default FormHOC(
    Form,
    {
        login: '',
        password: '',
    },
    validator,
);
