/* eslint-disable */
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { withTranslate } from 'react-redux-multilingual';
import { BalanceType, GlobalGameTypeEnum, SystemProvider } from 'src/assets/common/enum';
import DatePickerComponent from 'src/components/UI/DatePickerComponentWithTime/DatePickerComponent';
import { api } from 'src/core/api';
import { dateToTimestamp } from 'src/utils/helpers';

import Button from '../UI/Button';
import Input from '../UI/Input';
import OutlinedDropdown from '..//UI/outlinedDropdown';

import { validateString } from './validate';

import './styles.scss';
import { jwtDecode } from 'jwt-decode';

interface IUserBalanceForm {
    translate: (key: string) => string;
}

interface ISelected {
    label: string;
    value: number;
}

export const premisionLevel = () => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    if (!token) return 0;
    const decodedToken: { jwtPayloadData: { permissionLevel: number } } = jwtDecode(token);
    const { permissionLevel } = decodedToken.jwtPayloadData;
    return permissionLevel;
};

const UserCreateBalanceForm: React.FC<IUserBalanceForm> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [createOptions, setCreateOptions] = useState({
        amount: '',
        gameProvider: '',
        providerGameId: '',
        providerGameName: '',
        providerTransactionId: '',
        type: null,
        systemProvider: null,
        providerGameType: null,
        createDate: new Date(),
        target: '',
        requestToken: '',
    });

    const handleChangeInput = (e) => {
        const { value } = e.target;
        const key = e.target.id;
        setCreateOptions({ ...createOptions, [key]: value });
    };

    const getOptionBalanceType = () => {
        const enumKeys = Object.keys(BalanceType)
            .filter((key) => Number.isNaN(Number(BalanceType[key])))
            .map((key) => ({
                label: BalanceType[key],
                value: Number(key),
            }));
        return enumKeys.filter(
            (item) =>
                item.value !== BalanceType.TRANSFER &&
                item.value !== BalanceType.TAKE &&
                item.value !== BalanceType.SCEDUAL_REDUCE &&
                item.value !== BalanceType.SCEDUAL_REDUCE_BALANCE,
        );
    };

    const getOptionSystemProvider = () => {
        const enumKeys = Object.keys(SystemProvider)
            .filter((key) => Number.isNaN(Number(SystemProvider[key])))
            .map((key) => ({
                label: SystemProvider[key],
                value: key,
            }));
        return enumKeys;
    };

    const getOptionProviderGameType = () => {
        const enumKeys = Object.keys(GlobalGameTypeEnum)
            .filter((key) => Number.isNaN(Number(GlobalGameTypeEnum[key])))
            .map((key) => ({
                label: GlobalGameTypeEnum[key],
                value: key,
            }));
        return enumKeys;
    };

    const handleChangeProviderGameType = (type: ISelected) => {
        setCreateOptions({ ...createOptions, providerGameType: type });
    };

    const handleChangeBalanceType = (type: ISelected) => {
        setCreateOptions({ ...createOptions, type });
    };

    const handleChangeSystemProvider = (type: ISelected) => {
        setCreateOptions({ ...createOptions, systemProvider: type });
    };

    const handleChangeDate = (date) => {
        setCreateOptions({ ...createOptions, createDate: date });
    };

    const clickCreate = async () => {
        setIsLoading(true);
        const { type, systemProvider, providerGameType, createDate, ...remainPtion } = createOptions;
        const options = {
            ...remainPtion,
            // target,
            type: type ? type.value : null,
            systemProvider: systemProvider ? systemProvider.value : null,
            providerGameType: providerGameType ? providerGameType.value : null,
            createDate: dateToTimestamp(createDate),
            requestToken: '72bca94589e1ec3b1d721503be440a73',
        };
        for (const key in options) {
            if (options[key] === '') {
                delete options[key];
            }
        }
        for (const key in options) {
            if (options[key] === null) {
                delete options[key];
            }
        }
        const resp = await api.post('/balance/3x3cut3/op3rat1on-f1e2d34d5e6f', options);
        if (resp.data) {
            setIsLoading(false);
            NotificationManager.success(`${props.translate('Success')}`, '', 3500);
        } else {
            setIsLoading(false);
        }
    };

    const isDisableButton = () => {
        if (createOptions.amount.length === 0) return true;
        if (createOptions.type === null) return true;
        if (createOptions.systemProvider === null) return true;
    };

    if (premisionLevel() !== 8) return;

    return (
        <div className="Account" style={{ margin: 'auto', width: '100%' }}>
            <div className="UserInfo">
                <div className="UserInfo-content-step-1">
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={`${props.translate('Amount')}*`}
                            placeholder={props.translate('Amount')}
                            value={createOptions.amount}
                            id="amount"
                            onChange={handleChangeInput}
                        />
                        <div className="UserInfo-content-step-1-country">
                            <span className="UserInfo-content-step-1-country-title">{props.translate('Balance_type')}*</span>
                            <OutlinedDropdown
                                className="UserInfo-content-step-1-country-dropdown"
                                placeholder={props.translate('Balance_type')}
                                options={getOptionBalanceType()}
                                onSelect={handleChangeBalanceType}
                                defaultSelected={createOptions.type}
                            />
                        </div>
                    </div>
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translate('Game_provider')}
                            placeholder={props.translate('Game_provider')}
                            value={createOptions.gameProvider}
                            id="gameProvider"
                            onChange={handleChangeInput}
                            translate={props.translate}
                            error={validateString(createOptions.gameProvider)}
                        />
                        <div className="UserInfo-content-step-1-country">
                            <span className="UserInfo-content-step-1-country-title">{props.translate('System_provider')}*</span>
                            <OutlinedDropdown
                                className="UserInfo-content-step-1-country-dropdown"
                                placeholder={props.translate('System_provider')}
                                options={getOptionSystemProvider()}
                                onSelect={handleChangeSystemProvider}
                                defaultSelected={createOptions.systemProvider}
                            />
                        </div>
                    </div>
                    <div className="UserInfo-content-step-1-inputs">
                        <div className="UserInfo-content-step-1-country">
                            <span className="UserInfo-content-step-1-country-title">{props.translate('Provider_game_type')}</span>
                            <OutlinedDropdown
                                className="UserInfo-content-step-1-country-dropdown"
                                placeholder={props.translate('Provider_game_type')}
                                options={getOptionProviderGameType()}
                                onSelect={handleChangeProviderGameType}
                                defaultSelected={createOptions.providerGameType}
                            />
                        </div>
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translate('Provider_game_id')}
                            placeholder={props.translate('Provider_game_id')}
                            value={createOptions.providerGameId}
                            id="providerGameId"
                            type="number"
                            onChange={handleChangeInput}
                        />
                    </div>
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translate('Provider_game_name')}
                            placeholder={props.translate('Provider_game_name')}
                            value={createOptions.providerGameName}
                            id="providerGameName"
                            onChange={handleChangeInput}
                            translate={props.translate}
                            error={validateString(createOptions.providerGameName)}
                        />
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={props.translate('Provider_transaction_id')}
                            placeholder={props.translate('Provider_transaction_id')}
                            value={createOptions.providerTransactionId}
                            id="providerTransactionId"
                            onChange={handleChangeInput}
                            translate={props.translate}
                            error={validateString(createOptions.providerTransactionId)}
                        />
                    </div>

                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={`${props.translate('Target')}*`}
                            placeholder={props.translate('Target')}
                            value={createOptions.target}
                            id="target"
                            onChange={handleChangeInput}
                        />
                        <div className="UserInfo-content-step-1-country">
                            <span className="UserInfo-content-step-1-country-title">{props.translate('Date')}*</span>
                            <DatePickerComponent value={createOptions.createDate} onChange={handleChangeDate} />
                        </div>
                    </div>
                    {/* <div className="UserInfo-content-step-1-inputs"> */}
                    <Input
                        className="UserInfo-content-step-1-input key-input"
                        label={`${props.translate('Key')}*`}
                        placeholder={props.translate('Key')}
                        value={createOptions.requestToken}
                        id="requestToken"
                        onChange={handleChangeInput}
                    />
                    {/* </div> */}
                    <div className="UserInfo-content-step-1-buttons">
                        <Button
                            className="UserInfo-content-step-2-prev"
                            title={props.translate('Create')}
                            primary
                            onClick={clickCreate}
                            loading={isLoading}
                            disabled={isDisableButton()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslate(UserCreateBalanceForm);
